<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>{{ $route.name }}</span>
    </div>
    <h1>{{ $route.name }}</h1>
    <div class="paragraph-template">
     Textarea components are used for collecting large amounts of textual data.
    </div>
    <div class="paragraph-template-sub">
      Textarea in its simplest form is a multi-line text-field, useful for larger amounts of text.
    </div>

    <div class="paragraph-template">
      <h3> Textarea variants</h3>
    </div>
    <div class="paragraph-template-sub">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
    </div>
     <div class="paragraph-template-sub">
        Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafTextarea" target="_blank">Textarea documentation <i class="far fa-arrow-alt-circle-right"></i></a>
    </div>

    <v-app id="inspire">

      <div class="container-div with-border">
        <div class="paragraph-template">
            Default style
        </div>
        <div class="paragraph-template-sub"> 
           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea color="secondary" name="input-7-1" label="Default style"  value="" hint="Hint text"></v-textarea>
          </v-col>
        </v-row>
      </div>

      <div class="container-div with-border">
        <div class="paragraph-template">
          Disabled
        </div>
        <div class="paragraph-template-sub"> 
           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
        <v-row>
          <v-col cols="12" md="6">
             <v-textarea disabled="disabled" color="black" label="Text Area Disabled" ></v-textarea>
          </v-col>
        </v-row>
      </div>

      <div class="container-div with-border">
        <div class="paragraph-template">
          Text Area Error
        </div>
        <div class="paragraph-template-sub"> 
           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea   color="red"  label="Text Area Error" ></v-textarea>
          </v-col>
        </v-row>
      </div>

      <div class="container-div ">
        <div class="paragraph-template">
          Text Area Required
        </div>
        <div class="paragraph-template-sub"> 
           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
        <v-row>
          <v-col cols="12" md="6">
             <v-textarea color="purple" label="Text Area Required" ></v-textarea>
             
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>

</template>


<style scoped lang="scss">

.v-label--active{
  top:-8px!important;
}

.v-textarea textarea {
   border: 1px solid red;
}

</style>

<script>
import Vuetify from 'vuetify/lib'

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
  },
})
</script>
