<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>Themes</span>
    </div>
    <h1>Themes</h1>
    <div class="paragraph-template">
      Each Netwheels application has it’s own distinctive theme. While sharing fundamental components and elements trough all the applications, an application color palette is used to differentiate each individually.
    </div>
   
    <div class="paragraph-template">
     About Applications
    </div>
    <div class="paragraph-template-sub">
      Applications have their own color palette for creating distinguishable look and feel for each individual application. Each application palette is supported with all the primary grays from the primary color palette.
    </div>
   
    <div class="paragraph-template">
     Application Themes
    </div>

    <div class="component-list-div">
      <v-row>
 
        <v-col cols="12" sm="6" md="4" v-for="item in componentsItem"  :key="item.id">
          <router-link :to="{path:item.link}" class="sub-components">
            <div class="component-box">
              <div class="component-title">{{item.title}}</div>
              <div class="component-content">{{item.content}}</div>
              <div class="component-button"><span>View<i class="far fa-arrow-alt-circle-right"></i></span></div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<style scoped lang="scss">

.component-list-div{
  margin-top: 20px;
  a{
    text-decoration: none;
    .component-box{
      background-color:#F2F2F2;
      padding: 20px;
      margin-bottom: 20px;
      position: relative;
      min-height: 250px;
      .component-title{
        font-size: 20px;
        margin-bottom: 10px;
      }

      .component-content{
        margin-bottom: 40px;
      }

      .component-button{
        position: absolute;
        bottom: 20px;
        i{
          padding-left: 10px;
        }
      }
    }

  }
  a:hover {
     text-decoration: none;
  }
}
</style>

<script>
  import uniqueId from 'lodash.uniqueid'
  export default {
  name: 'app',
  
  data() {
    return {
      componentsItem: [
        { 
          id: uniqueId('todo-'),
          title: 'Autohuuto', 
          content: 'Autohuuto is an inter-company auction service that sells items from larger used car chains and dealerships. There is a selection of thousands of cars on sale every year in all price ranges.', 
          link: '/theme/autohuuto'
        },
        { id: uniqueId('todo-'),
          title: 'GT-X', 
          content: 'The GT-X software consists of two main modules. The vehicle modeling and warehouse, which handles distribution channel sales and inventory management.', 
          link: '/theme/gt-x'
        },
        
      ]
    };
  },
  
};

</script>


