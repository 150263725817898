<template>
    <div class="right-container">
        <div class="breadcrumbs-div">
            <router-link to="/" class="breadcrumbs-home">
                <span>NUIG Home</span>
            </router-link>
            <i class="fas fa-chevron-right"></i>
            <span>{{ $route.name }}</span>
        </div>
        <h1>{{ $route.name }}</h1>

        <div class="paragraph-template">
            The checkbox component provides users the ability to choose between two distinct values. These are very similar to a switch and can be used in complex forms and checklists. A simpler version, v-simple-checkbox is used primarily as a lightweight alternative in data-table components to select rows or display inline boolean data.
        </div>

        <div class="paragraph-template">
            <h3>Checkbox variants</h3>
        </div>

        <div class="paragraph-template-sub">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>

        <div class="paragraph-template-sub">
            Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafCheckbox" target="_blank">Checkbox documentation <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>
    
        <v-app id="inspire">
            <div class="container-div with-border">
                <div class="paragraph-template">
                    Single Selection
                </div>
                <div class="paragraph-template-sub"> 
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <div class="mini-titlle">Default style</div>
                <div class="same-row">
                    <v-checkbox color="secondary"  v-model="checkbox1" :label="` ${checkbox1.toString()}`" ></v-checkbox>
                    <v-checkbox color="secondary" v-model="checkbox2" :label="` ${checkbox2.toString()}`"  ></v-checkbox>
                </div>
            </div>
            <div class="container-div with-border">
                <div class="paragraph-template">
                    Selection with label
                </div>
                <div class="paragraph-template-sub"> 
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <div class="mini-titlle">Labeled group</div>
                <div class="mini-titlle">{{ selected }}</div>
                <div class="same-row">
                    <v-checkbox color="secondary" v-model="selected" label="Alpha"  value="Alpha" ></v-checkbox>
                    <v-checkbox color="secondary" v-model="selected" label="Bravo" value="Bravo" ></v-checkbox>
                </div>
            </div>   

            <div class="container-div">
                <div class="paragraph-template">
                    Checkboxes with message
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <div class="mini-titlle">Large checkboxes with message (height grows automaticly)</div>

                <div class="mini-titlle">{{ selected1 }}</div>
                
                <v-checkbox color="secondary" v-model="selected1" label="Alpha" value="Alpha" messages="Väli on korkeampi" ></v-checkbox>

                <v-checkbox color="secondary" v-model="selected1" label="Bravo" value="Bravo" > 
                    <template v-slot:label>
                        <div>
                            Bravo
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a target="_blank" href="https://www.autoalanverkkopalvelu.fi/NWNet/NW_Yleiset_ehdot.pdf" @click.stop secondary v-on="on" > Link here </a>
                                </template>
                                    Opens in new window
                            </v-tooltip>
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="secondary" v-model="selected1" label="Cat" value="Cat" ></v-checkbox>
            </div>
        </v-app>
    </div>
</template>


<style scoped lang="scss">

.row{
    flex: initial!important;
}


.same-row{
    display: inline-flex;
    .v-input{
        margin-right: 15px;
    }
}

.v-input--is-label-active .v-label {
    font-weight: 500;
    color: #353535;
}
</style>


<script lang="js">

export default {

    data () {
        return {
            checkbox1: true,
            checkbox2: false,

            selected: ['Alpha'],
            selected1:['Alpha'],
        }
    },
}

</script>
