<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>{{ $route.name }}</span>
    </div>
    <h1>{{ $route.name }}</h1>
    <div class="paragraph-template">
        Naf-fab Menu component shows a menu at the position of the element used to activate it.
    </div>

    <div class="paragraph-template">
      <h3>Naf-fab menu variants</h3>
    </div>
    <div class="paragraph-template-sub">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
    </div>
    <div class="paragraph-template-sub">
        Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafFabMenu" target="_blank"> Fab-menu documentation <i class="far fa-arrow-alt-circle-right"></i></a>
    </div>

   
      <v-app id="inspire">
        <div class="container-div with-border">
          <div class="paragraph-template">
              As a menu  
          </div>
          <div class="paragraph-template-sub"> 
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
          </div>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <div class="text-center">
                <v-menu top :close-on-content-click="closeOnContentClick" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark v-bind="attrs" v-on="on" >
                      <i class="fas fa-car"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in items"  :key="index" >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
      
        <div class="container-div with-border">
          <div class="paragraph-template">
            Disable
          </div>
          <div class="paragraph-template-sub"> 
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
          </div>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <div class="text-center">
                <v-menu   disabled top offset-y >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark v-bind="attrs" v-on="on" >
                      <i class="fas fa-car"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in items"  :key="index" >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
    
        <div class="container-div with-border">
          <div class="paragraph-template">
            Opens top left
          </div>
          <div class="paragraph-template-sub"> 
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
          </div>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <div class="text-center">
                <v-menu transition="slide-x-transition" top left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark v-bind="attrs" v-on="on" >
                      <i class="fas fa-car"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in items"  :key="index" >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                </div>
            </v-col>
          </v-row>
        </div>

        <div class="container-div with-border">
          <div class="paragraph-template">
            With custom content
          </div>
          <div class="paragraph-template-sub"> 
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
          </div>

          <v-row>
            <v-col cols="12" sm="4" md="4">
              <div class="text-center">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark  v-bind="attrs" v-on="on" >
                      <i class="fas fa-car"></i>
                    </v-btn>
                  </template>
            
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-action>
                          <v-switch v-model="message" color="purple" ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>Enable messages</v-list-item-title>
                      </v-list-item>
            
                      <v-list-item>
                        <v-list-item-action>
                          <v-switch  v-model="hints" color="purple" ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>Enable hints</v-list-item-title>
                      </v-list-item>
                    </v-list>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text  @click="menu = false"  > Cancel </v-btn>
                      <v-btn  color="primary" text @click="menu = false"  >  Save </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
       </v-app>
    </div>

</template>

<style scoped lang="scss">


button{
    height: 50px!important;
    width: 50px!important;
    border-radius: 50%!important;
    min-width: 50px!important;
}

.fas{
    font-size: 22px;
}

.row{
    flex: initial!important;
}

</style>



<script lang="js">

export default {
  data: () => ({
    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me 2' },
    ],
    closeOnContentClick: true,
  }),

}

</script>
