<template>
    <div class="right-container">
        <div class="breadcrumbs-div">
            <router-link to="/" class="breadcrumbs-home">
                <span>NUIG Home</span>
            </router-link>
            <i class="fas fa-chevron-right"></i>
            <span>{{ $route.name }}</span>
        </div>
        <h1>{{ $route.name }}</h1>
        <div class="paragraph-template">
        The dialog component inform users about a specific task and may contain critical information, require decisions, or involve multiple tasks. Use dialogs sparingly because they are interruptive.
        </div>


        <div class="paragraph-template">
        <h3>Snackbar variants</h3>
        </div>
        <div class="paragraph-template-sub">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>
        <div class="paragraph-template-sub">
            Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafSnackbar" target="_blank">Snackbar documentation <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>

        <v-app id="inspire">
            <div class="container-div with-border">
                <div class="paragraph-template">
                    Success snackbar H3 and parargraph
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-btn dark color="secondary" @click="snackbar = true" large tile class="black--text">
                            Open Snackbar
                        </v-btn>
                        <v-snackbar v-model="snackbar" :multi-line="multiLine" color="green" >
                            <h3>{{ text }}</h3>
                            <p>{{ text3}}</p>
                            <template v-slot:action="{ attrs }">
                                <v-btn color="white" text v-bind="attrs" @click="snackbar = false" > x </v-btn>
                            </template>
                        </v-snackbar>
                    </v-col>
                </v-row>
            </div>

            <div class="container-div with-border">
                <div class="paragraph-template">
                    Error snackbar
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-btn dark color="error" @click="snackbarerror = true" large tile class="black--text">
                            Open Snackbar
                        </v-btn>
                        <v-snackbar v-model="snackbarerror" :multi-line="multiLine" color="red" >
                            <h3>Error</h3>
                            <template v-slot:action="{ attrs }">
                            <v-btn color="white" text v-bind="attrs" @click="snackbarerror = false" > x </v-btn>
                            </template>
                        </v-snackbar>
                    </v-col>
                </v-row>
            </div>

            <div class="container-div with-border">
                <div class="paragraph-template">
                    Info snackbar
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-btn dark color="required" @click="snackbarInfo = true" large tile class="black--text">
                            Open Snackbar
                        </v-btn>
                        <v-snackbar v-model="snackbarInfo" :multi-line="multiLine" color="deep-purple darken-1" >
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <template v-slot:action="{ attrs }">
                                <v-btn color="white" text v-bind="attrs" @click="snackbarInfo = false" >  x </v-btn>
                            </template>
                        </v-snackbar>
                    </v-col>
                </v-row>
            </div>

            <div class="container-div">
                <div class="paragraph-template">
                    Warning Snackbar
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>

                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-btn dark color="warning" @click="snackbarWarning = true" large tile class="black--text">
                            Open Snackbar
                        </v-btn>
                        <v-snackbar v-model="snackbarWarning" :multi-line="multiLine" color="yellow darken-3" >
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <template v-slot:action="{ attrs }">
                                <v-btn color="white" text v-bind="attrs" @click="snackbarWarning = false" > x </v-btn>
                            </template>
                        </v-snackbar>
                    </v-col>
                </v-row>
            </div>
        </v-app>
    </div>

</template>


<style scoped lang="scss">

.row{
    flex: initial!important;
}

button{
    font-weight: 300;
}

.none{
    display: none;
}

</style>


<script lang="js">


export default {
   
   data: () => ({
    multiLine: true,
    snackbar: false,
    text: `Success`,
    text3: `It's a success snackbar`,

    snackbar2: false,
    text2: 'My timeout is set to 2000.',
    timeout: 2000,

    snackbarerror:false,
    snackbarInfo:false,
    snackbarWarning:false,
   })

 
}


</script>
