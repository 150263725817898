var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-container"},[_c('div',{staticClass:"breadcrumbs-div"},[_c('router-link',{staticClass:"breadcrumbs-home",attrs:{"to":"/"}},[_c('span',[_vm._v("NUIG Home")])]),_c('i',{staticClass:"fas fa-chevron-right"}),_c('span',[_vm._v(_vm._s(_vm.$route.name))])],1),_c('h1',[_vm._v(_vm._s(_vm.$route.name))]),_c('div',{staticClass:"paragraph-template"},[_vm._v(" Naf-fab Menu component shows a menu at the position of the element used to activate it. ")]),_vm._m(0),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ")]),_vm._m(1),_c('v-app',{attrs:{"id":"inspire"}},[_c('div',{staticClass:"container-div with-border"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" As a menu ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"top":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-car"})])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)])],1)],1),_c('div',{staticClass:"container-div with-border"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" Disable ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"disabled":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-car"})])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)])],1)],1),_c('div',{staticClass:"container-div with-border"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" Opens top left ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"transition":"slide-x-transition","top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-car"})])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)])],1)],1),_c('div',{staticClass:"container-div with-border"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" With custom content ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-car"})])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-action',[_c('v-switch',{attrs:{"color":"purple"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-list-item-title',[_vm._v("Enable messages")])],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-switch',{attrs:{"color":"purple"},model:{value:(_vm.hints),callback:function ($$v) {_vm.hints=$$v},expression:"hints"}})],1),_c('v-list-item-title',[_vm._v("Enable hints")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Save ")])],1)],1)],1)],1)])],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template"},[_c('h3',[_vm._v("Naf-fab menu variants")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Click here to read: "),_c('a',{staticClass:"arrow-new-tab",attrs:{"href":"https://naf.demo.netwheels.fi/website/#/components/NafFabMenu","target":"_blank"}},[_vm._v(" Fab-menu documentation "),_c('i',{staticClass:"far fa-arrow-alt-circle-right"})])])}]

export { render, staticRenderFns }