<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>{{ $route.name }}</span>
    </div>
    <h1>{{ $route.name }}</h1>
    <div class="paragraph-template">
     Date pickers allow users to select a time or date, or schedule tasks.
    </div>

    <div class="paragraph-template-sub">
     Date pickers come in two orientation variations, portrait (default) and landscape. By default they are emitting input event when the day (for date picker) or month (for month picker), but with reactive prop they can update the model even after clicking year/month.
    </div>

    <div class="paragraph-template">
      <h3> Datepicker variants</h3>
    </div>
    <div class="paragraph-template-sub">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
    </div>
    <div class="paragraph-template-sub">
      Click here to read: <a class="arrow-new-tab" href="#" target="_blank"> Datepicker documentation <i class="far fa-arrow-alt-circle-right"></i></a>
    </div>

    <div class="paragraph-template">
      Date Pickers
    </div>
    
      <v-app id="inspire">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-btn-toggle v-model="toggle_exclusive">
            <v-icon dark left >mdi-calendar </v-icon>
            <v-menu ref="menu"   v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field  color="secondary"  v-model="date" label="Picker in menu" prepend-icon="" readonly v-bind="attrs" v-on="on" ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable color="secondary">
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="menu = false" >
                  Cancel
                </v-btn>
                <v-btn  text color="secondary" @click="$refs.menu.save(date)" >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4" >
            <v-btn-toggle >
            <v-icon dark left >  mdi-calendar </v-icon>
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field  color="secondary" v-model="date" label="Picker in dialog" prepend-icon="" readonly v-bind="attrs" v-on="on"  ></v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable  color="secondary">
                <v-spacer></v-spacer>
                <v-btn text color="secondary"  @click="modal = false" >
                  Cancel
                </v-btn>
                <v-btn text color="secondary" @click="$refs.dialog.save(date)" >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-btn-toggle>
        </v-col>
        <v-col  cols="12"  sm="6" md="4" >
          <v-btn-toggle >
            <v-icon dark left >  mdi-calendar </v-icon>
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="secondary" v-model="date" label="Picker without buttons" prepend-icon="" readonly v-bind="attrs" v-on="on" ></v-text-field>
              </template>
              <v-date-picker v-model="date" @input="menu2 = false" color="secondary" ></v-date-picker>
            </v-menu>
          </v-btn-toggle>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-app>
  </div>

</template>

<script>

export default {
    data: () => ({
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    }),
  }

</script>

<style scoped lang="scss">


  .calendar-div{
    display:flex;
    border: 1px solid #E3E3E3;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 4px 0 #e3e3e3;
    -moz-box-shadow: 0 2px 4px 0 #E3E3E3;
    box-shadow: 0 2px 4px 0 #e3e3e3;
    padding: 10px;
    i{
      font-size: 24px;
      padding-right: 5px;
    }
    .vdp-datepicker{
        width: 100%;      
    }
  }


.mdi-calendar::before {
    color: #267B8F!important;
}

.calendar-box{
    border:none;
    width: 100%;
}

input:focus, textarea {
    outline: none !important;
}

</style>











