<template>
  <div class="right-container">
        <div class="breadcrumbs-div">
        <router-link to="/" class="breadcrumbs-home">
            <span>NUIG Home</span>
        </router-link>
        <i class="fas fa-chevron-right"></i>
        <span>{{ $route.name }}</span>
        </div>
        <h1>{{ $route.name }}</h1>
        <div class="paragraph-template">
            The radio component is a simple radio button. When combined with the v-radio-group component you can provide groupable functionality to allow users to select from a predefined set of options.
        </div>

        <div class="paragraph-template">
        <h3>Radio variants</h3>
        </div>
        <div class="paragraph-template-sub">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>

        <div class="paragraph-template-sub">
            Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafRadioGroup" target="_blank">Radio button documentation <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>
    
        <v-app id="inspire">
            <div class="container-div with-border">
                <div class="paragraph-template">
                    Single Selection
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>

                <v-row>
                    <v-col cols="6" sm="5" >
                        <div class="mini-titlle">Default radio button</div>
                        <v-radio-group v-model="row" row >
                            <v-radio color="secondary" label="Option 1" value="radio-1"></v-radio>
                            <v-radio color="secondary" label="Option 2" value="radio-2" ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="6" sm="5">
                        <div class="mini-titlle">Disabled</div>
                        <v-radio-group v-model="row" row disabled >
                            <v-radio label="Option 1" value="radio-1"></v-radio>
                            <v-radio label="Option 2" value="radio-2" ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </div>

            <div class="container-div">
                <div class="paragraph-template">
                    Multiple Selection
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
                <v-row>
                    <v-col cols="6" sm="5" >
                        <div class="mini-titlle">Multiple radio button</div>
                        <v-radio-group v-model="radioGroup" multiple row >
                            <v-radio color="secondary" v-for="n in 3" :key="n" :label="`Radio ${n}`" :value="n"  ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="6" sm="5">
                        <div class="mini-titlle">Disabled</div>
                            <v-radio-group v-model="radioGroup2" multiple row disabled>
                            <v-radio color="secondary" v-for="n in 3" :key="n" :label="`Radio ${n}`" :value="n"  ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </div>    
        </v-app>
    </div>
</template>


<style scoped lang="scss">

.v-radio.v-item--active {
    font-weight: 500;
}

.row{
    flex: initial!important;
}

.spacer-bottom{
    margin-bottom: 50px;
}

.none{
    display: none;
}

</style>


<script lang="js">


export default {

    data () {
        return {
        radioGroup: 1,
        radios: null,
        }
    }
}

</script>
