var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-container"},[_c('div',{staticClass:"breadcrumbs-div"},[_c('router-link',{staticClass:"breadcrumbs-home",attrs:{"to":"/"}},[_c('span',[_vm._v("NUIG Home")])]),_c('i',{staticClass:"fas fa-chevron-right"}),_c('span',[_vm._v(_vm._s(_vm.$route.name))])],1),_c('h1',[_vm._v(_vm._s(_vm.$route.name))]),_c('div',{staticClass:"paragraph-template"},[_vm._v(" The dialog component inform users about a specific task and may contain critical information, require decisions, or involve multiple tasks. Use dialogs sparingly because they are interruptive. ")]),_vm._m(0),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ")]),_vm._m(1),_c('v-app',{attrs:{"id":"inspire"}},[_c('div',{staticClass:"container-div with-border"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" Success snackbar H3 and parargraph ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"black--text",attrs:{"dark":"","color":"secondary","large":"","tile":""},on:{"click":function($event){_vm.snackbar = true}}},[_vm._v(" Open Snackbar ")]),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine,"color":"green"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" x ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h3',[_vm._v(_vm._s(_vm.text))]),_c('p',[_vm._v(_vm._s(_vm.text3))])])],1)],1)],1),_c('div',{staticClass:"container-div with-border"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" Error snackbar ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"black--text",attrs:{"dark":"","color":"error","large":"","tile":""},on:{"click":function($event){_vm.snackbarerror = true}}},[_vm._v(" Open Snackbar ")]),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine,"color":"red"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbarerror = false}}},'v-btn',attrs,false),[_vm._v(" x ")])]}}]),model:{value:(_vm.snackbarerror),callback:function ($$v) {_vm.snackbarerror=$$v},expression:"snackbarerror"}},[_c('h3',[_vm._v("Error")])])],1)],1)],1),_c('div',{staticClass:"container-div with-border"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" Info snackbar ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"black--text",attrs:{"dark":"","color":"required","large":"","tile":""},on:{"click":function($event){_vm.snackbarInfo = true}}},[_vm._v(" Open Snackbar ")]),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine,"color":"deep-purple darken-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbarInfo = false}}},'v-btn',attrs,false),[_vm._v(" x ")])]}}]),model:{value:(_vm.snackbarInfo),callback:function ($$v) {_vm.snackbarInfo=$$v},expression:"snackbarInfo"}},[_c('p',[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.")])])],1)],1)],1),_c('div',{staticClass:"container-div"},[_c('div',{staticClass:"paragraph-template"},[_vm._v(" Warning Snackbar ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"black--text",attrs:{"dark":"","color":"warning","large":"","tile":""},on:{"click":function($event){_vm.snackbarWarning = true}}},[_vm._v(" Open Snackbar ")]),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine,"color":"yellow darken-3"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbarWarning = false}}},'v-btn',attrs,false),[_vm._v(" x ")])]}}]),model:{value:(_vm.snackbarWarning),callback:function ($$v) {_vm.snackbarWarning=$$v},expression:"snackbarWarning"}},[_c('p',[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.")])])],1)],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template"},[_c('h3',[_vm._v("Snackbar variants")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Click here to read: "),_c('a',{staticClass:"arrow-new-tab",attrs:{"href":"https://naf.demo.netwheels.fi/website/#/components/NafSnackbar","target":"_blank"}},[_vm._v("Snackbar documentation "),_c('i',{staticClass:"far fa-arrow-alt-circle-right"})])])}]

export { render, staticRenderFns }