<template>
  <div class="right-container">
        <div class="breadcrumbs-div">
            <router-link to="/" class="breadcrumbs-home">
                <span>NUIG Home</span>
            </router-link>
            <i class="fas fa-chevron-right"></i>
            <span>{{ $route.name }}</span>
        </div>
        <h1>{{ $route.name }}</h1>
        <div class="paragraph-template">
            The tags component is used to convey small pieces of information. Using the close property, the chip becomes interactive, allowing user interaction. This component is used by the v-chip-group for advanced selection options.
        </div>

        <div class="paragraph-template">
        <h3>Tags variants</h3>
        </div>
        
        <div class="paragraph-template-sub">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>

        <div class="paragraph-template-sub">
        Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafTag" target="_blank"> Tags documentation <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>

        <v-app id="inspire">
            <div class="container-div">
                <div class="paragraph-template">
                    Label
                </div>
                <div class="paragraph-template-sub"> 
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>

                <v-row>
                    <v-col cols="12" >
                        <v-row>
                            <v-col cols="6" sm="2">
                                <div class="mini-titlle">Default style</div>
                                <v-chip class="ma-2 white-btn" label color="white" :style=" 'border: 1px solid #595959; color:#595959;background:#fff'" >
                                    Default
                                </v-chip>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <div class="mini-titlle">With icon</div>
                                <v-chip class="ma-2 white-btn left-content" color="white" label  :style=" 'border: 1px solid #595959; color:#595959;background:#fff'"  >
                                    <v-icon left :style=" 'color:#000;'" > 
                                        mdi-car
                                    </v-icon>
                                    Tag with icon
                                </v-chip>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <div class="mini-titlle">With custom action</div>
                                <v-chip class="ma-2 white-btn right-content" color="white" label  :style=" 'border: 1px solid #595959; color:#595959;background:#fff'" >
                                    Custom action
                                    <v-icon right :style=" 'color:#000;'" > 
                                        mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <div class="mini-titlle">With close</div>
                                <v-chip  class="ma-2 white-btn right-content"   color="white" label   :style=" 'border: 1px solid #595959; color:#595959;background:#fff'" >
                                    With close action
                                    
                                        <v-icon right  :style=" 'color:#000;cursor:pointer;'" > mdi-close </v-icon>
                                        
                                </v-chip>
                            </v-col>
                        </v-row>
                        </v-col>
                </v-row>
            </div>
        </v-app>
    </div>
</template>


<style scoped lang="scss">

.row{
    flex: initial!important;
}

.white-btn{
    border-color: gray!important;
    border: 1px solid gray!important;
    border-radius: 20px!important;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px!important;
    color: #595959;
}

</style>


<script lang="js">


export default {
   

 data () {
   return {
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
    }
  },
}


</script>
