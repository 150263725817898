<template>
    <div class="right-container">
        <div class="breadcrumbs-div">
            <router-link to="/" class="breadcrumbs-home">
                <span>NUIG Home</span>
            </router-link>
            <i class="fas fa-chevron-right"></i>
            <span>{{ $route.name }}</span>
        </div>
        <h1>{{ $route.name }}</h1>
        <div class="paragraph-template">
            The dropdown component is a wrapper for the vuetify v-autocomplete component.
        </div>

        <div class="paragraph-template">
            <h3> Dropdown variants</h3>
        </div>
        
        <div class="paragraph-template-sub">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>
        <div class="paragraph-template-sub">
        Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafDropdown" target="_blank"> Dropdown documentation <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>

        
        <v-app id="inspire">
            <div class="container-div with-border">
                <div class="paragraph-template">
                    Single Dropdown
                </div>
                <div class="paragraph-template-sub"> 
                    Primary action button is the default button style used in all basic buttons to active positive functions and confirmations. It can be deployd in different sizes and shapes depending on layout composition.
                </div>

                <div class="spacer-bottom"> 
                    <v-row>
                        <v-col cols="12" sm="6" md="4" >
                            <div class="mini-titlle">Dropdown with title</div>
                            <v-autocomplete color="secondary" v-model="values" :items="items" dense label=" Dropdown with title"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" >
                            <div class="mini-titlle">Dropdown without title</div>
                            <v-autocomplete color="secondary" v-model="values" :items="items" dense ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" >
                            <div class="mini-titlle">Disabled</div>
                            <v-autocomplete color="secondary" v-model="values" :items="items" dense label="Disabled dropdown" disabled></v-autocomplete>
                        </v-col>
                    </v-row>     
                </div>
            </div>

            <div class="container-div">
                <div class="paragraph-template">
                    Multiple Dropdown
                </div>

                <div class="paragraph-template-sub"> 
                    Primary action button is the default button style used in all basic buttons to active positive functions and confirmations. It can be deployd in different sizes and shapes depending on layout composition.
                </div>

                <div class="spacer-bottom"> 
                    <v-row>
                        <v-col cols="12" sm="6" md="4" >
                            <div class="mini-titlle">Dropdown with title</div>
                            <v-autocomplete color="secondary"  v-model="values2" :items="items2" dense  multiple label=" Dropdown with title ">
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" >
                            <div class="mini-titlle">Dropdown without title</div>
                            <v-autocomplete color="secondary" v-model="values2" :items="items2" dense  multiple></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" >
                            <div class="mini-titlle">Disabled</div>
                            <v-autocomplete  color="secondary" v-model="values2" :items="items2" dense  multiple label="Disabled dropdown" disabled></v-autocomplete>
                        </v-col>  
                    </v-row>     
                </div>
            </div>
        </v-app>
    </div>  
</template>


<style scoped lang="scss">

.row{
    flex: initial!important;
}

</style>

<script lang="js">

export default {
    data: () => ({
    items: ['foo', 'bar', 'fizz', 'buzz'],
    items2: ['foo', 'bar', 'fizz', 'buzz' , 'auto'],
    values2: ['foo', 'auto'],
    values: ['foo', 'bar'],
    value: null,


   
  
  }),
}

</script>
