<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>{{ $route.name }}</span>
    </div>
    <h1>{{ $route.name }}</h1>
    <div class="paragraph-template">
    At the core fo Netwheels user interface are the components. How they are are designed and implemented, and how they react and respond when interacted with various devices and in different environments.
    </div>
   
    <div class="paragraph-template">
      Component fundamentals
    </div>
    <div class="paragraph-template-sub">
      With every user interface component designed and developed the overall dimensions are always divisible by eight (8) to maintain proper size contrast relation with layout. Naturally the width of each specific component varies context-wise.
    </div>
    <div class="paragraph-template-sub">
      Color and other visual variations for the components are derived fron the selected application theme. No other specific differences excist for the components and they are structuraly same trough use of all Netwheels user interfaces.
    </div>
    <div class="paragraph-template-sub">
      Each component is set to do a specific task and most of them also have individual specifications for various different states
    </div>

    <div class="paragraph-template">
      List of Components
    </div>

    <div class="component-list-div">
      <div class="row">
        <div class="col-12 col-sm-6 col-xl-4"  v-for="item in componentsItem" :key="item.id">
          <router-link :to="{path:item.link}" class="sub-components">
            <div class="component-box">
              <div class="component-title">{{item.title}}</div>
              <div class="component-content">{{item.content}}</div>
              <div class="component-button"><span>Read more<i class="far fa-arrow-alt-circle-right"></i></span></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="less">
.component-list-div{
  margin-top: 20px;
  a{
    text-decoration: none;
    .component-box{
      background-color:#F2F2F2;
      padding: 20px;
      margin-bottom: 20px;
      position: relative;
      min-height: 250px;
      .component-title{
        font-size: 20px;
        margin-bottom: 10px;
      }

      .component-content{
        margin-bottom: 40px;
      }

      .component-button{
        position: absolute;
        bottom: 20px;
        i{
          padding-left: 10px;
        }
      }
    }

  }
  a:hover {
     text-decoration: none;
  }
}
</style>


<script type="application/javascript" >

  import uniqueId from 'lodash.uniqueid'
  export default {
    
  name: 'app',
  
  data() {
    return {
      componentsItem: [
        { 
          id: uniqueId('todo-'),
          title: 'Button', 
          content: 'Buttons are one of the primary components of applications interaction. They are used to call  various actions by users and allow them to interact with pages in a variety of ways.', 
          link: '/components/button'
        },
        { id: uniqueId('todo-'),
          title: 'Date picker', 
          content: 'Date pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/date-picker'
        },
        { id: uniqueId('todo-'),
          title: 'Dropdown', 
          content: 'Date pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/dropdown'
        },
        { id: uniqueId('todo-'),
          title: 'File upload', 
          content: 'Date pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/file-upload'
        },
        { id: uniqueId('todo-'),
          title: 'Text area', 
          content: 'Date pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/text-area'
        },
        { id: uniqueId('todo-'),
          title: 'Text fieldsss', 
          content: 'Date pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/text-field'
        },
        { id: uniqueId('todo-'),
          title: 'Time picker', 
          content: 'Time pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/time-picker'
        }
        
      ]
    };

    
  }
};


</script>


