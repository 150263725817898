<template>
    <div class="right-container">
        <div class="breadcrumbs-div">
            <router-link to="/" class="breadcrumbs-home">
                <span>NUIG Home</span>
            </router-link>
            <i class="fas fa-chevron-right"></i>
            <span>{{ $route.name }}</span>
        </div>
        <h1>{{ $route.name }}</h1>
        <div class="paragraph-template">
        The dialog component inform users about a specific task and may contain critical information, require decisions, or involve multiple tasks. Use dialogs sparingly because they are interruptive.
        </div>

        <div class="paragraph-template">
        <h3> Dialog variants</h3>
        </div>
        <div class="paragraph-template-sub">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>
        
        <div class="paragraph-template-sub">
            Click here to read: <a class="arrow-new-tab" href="https://naf.demo.netwheels.fi/website/#/components/NafDialog" target="_blank">Dialog documentation <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>


        <v-app id="inspire">
            <div class="container-div with-border">
                <div class="paragraph-template">
                    Naf dialog
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </div>

                <v-row>
                    <v-col cols="12" sm="6" md="2">
                        <div class="mini-titlle">Default</div>
                        <v-dialog v-model="dialog10" width="800" tile  class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="third" dark v-bind="attrs" v-on="on" class="black--text"  large tile>
                                    Open
                                </v-btn>
                            </template>
                            <v-card tile>
                                <v-card-title class="text-h5 text-white black lighten-2">
                                    TEKSTI 1
                                </v-card-title>
                                <v-card-title class="text-h6 text-black white lighten-2 shadow" style="position:relative">
                                    Teksti 2
                                    <div style="position:absolute;right:20px;">
                                        <v-btn depressed large class="black--text" color="white" tile button @click="dialog10 = false" >
                                            <i class="fas fa-undo" left  ></i><span>Peruuta</span> 
                                        </v-btn>

                                        <v-btn depressed large class="black--text" color="white"  :loading="loading3" @click="loader = 'loading3'" tile>
                                            <i class="far fa-save" left></i>Tallena
                                        </v-btn>
                                    </div>
                                </v-card-title>

                                <v-card-text style="padding: 40px 24px 40px;">
                                    Tänne sisältöä
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" sm="6" md="5">
                        <div class="mini-titlle">Open Dialog with  footer and customs action</div>
                        <v-dialog v-model="dialog11" width="1100" tile >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="third" dark v-bind="attrs" v-on="on" class="black--text"  large tile>
                                    Open
                                </v-btn>
                            </template>
                            <v-card >
                                <v-card-title class="text-h5 text-white black lighten-2">
                                    TÄMÄ DIALOGI ON LEVEÄMPI
                                    <div style="position:absolute;right:15px;">
                                            <v-btn icon dark @click="dialog11 = false" >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-title>
                                <v-card-title class="text-h6 text-black white lighten-2 shadow" style="position:relative">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit

                                    <div style="position:absolute;right:0px;display: flex;align-items: baseline;right:15px">
                                        <v-text-field label="" placeholder=""></v-text-field>
                                        <v-btn depressed large  :style=" 'margin-left:15px;border: 1px solid #267B8F; color:#267B8F;background:#fff'" class="white-btn" tile >
                                            <i class="fas fa-dog" left></i>
                                            <span>Custom action</span>
                                        </v-btn>
                                    </div>
                                </v-card-title>

                                <v-card-text style="padding: 40px 24px 40px;">
                                        <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Dialogin voi asettaa normaalia leveämmäksi tai kapeammaksi. Default 800px</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Default action painikkeet peruuta ja tallenna on korvattu custom actioneilla jotka on laitettu custom-actions slottiin.</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Custom action painikkeella voi asettaa headerin active-state ja pois.</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions class="justify-end">
                                        <v-btn depressed fab  :style=" 'border: 1px solid #267B8F; color:#267B8F;background:#fff;margin-bottom:10px'" class="white-btn" >
                                        <i class="far fa-question-circle"></i>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" sm="6" md="5">
                        <div class="mini-titlle">Open full screen dialog disabled save</div>
                        <v-dialog v-model="dialog12" fullscreen hide-overlay transition="dialog-bottom-transition"  tile >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="third" dark v-bind="attrs" v-on="on" class="black--text"  large tile>
                                    Open
                                </v-btn>
                            </template>
                            <v-card >
                                <v-card-title class="text-h5 text-white black lighten-2">
                                    TEKSTI 1
                                    <v-text-field  label="" placeholder=""></v-text-field>
                                    <div style="position:absolute;right:15px;">
                                            <v-btn icon dark @click="dialog12 = false" >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-title>
                                <v-card-title class="text-h6 text-black white lighten-2 shadow" style="position:relative">
                                    Teksti 2

                                    <div style="position:absolute;right:25px;">
                                        <v-btn depressed large class="black--text" color="white" tile button @click="dialog12 = false" >
                                            <i class="fas fa-undo" left  ></i><span>Peruuta</span> 
                                        </v-btn>

                                        <v-btn depressed large class="black--text" color="white" tile disabled>
                                            <i class="far fa-save" left></i><span>Tallena</span> 
                                        </v-btn>
                                    </div>
                                </v-card-title>

                                <v-card-text style="padding: 40px 24px 40px;">
                                        <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Tänne sisältöä</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>                                        
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </div>

            <div class="container-div ">
                <div class="paragraph-template">
                    Naf-confirmation-dialog
                </div>
                <div class="paragraph-template-sub"> 
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </div>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <div class="mini-titlle">With title, subtile and confirmation button</div>
                        <v-dialog v-model="dialog13" width="500" tile >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="third" dark v-bind="attrs" v-on="on" class="black--text"  large tile>
                                    Open
                                </v-btn>
                            </template>
                            <v-card >
                                <v-card-title class="text-h5 text-white black lighten-2">
                                    OTSIKKO
                                    <div style="position:absolute;right:20px;">
                                            <v-btn icon dark @click="dialog13 = false" >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-title>
                                <v-card-title class="text-h6 white lighten-2 shadow" style="color:#52D6CD">
                                    Ohjeteksti
                                </v-card-title>

                                <v-card-text style="padding: 40px 24px 40px;align:center;">
                                    Tänne sisältöä
                                </v-card-text>

                                <v-card-actions class="justify-end">
                                        <v-btn large class="naf-btn btnBlock" :style=" 'border:0px solid #fff; color:#000; background:#fff;box-shadow: none;'" tile button @click="dialog13 = false">
                                        PERUUTA
                                    </v-btn>
                                    <v-btn depressed large  :style=" 'border: 1px solid #267B8F; color:#267B8F;background:#fff'" class="white-btn" tile button @click="dialog13 = false" >
                                        VAHVISTA
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                        <div class="mini-titlle">Without title, subtile and confirmation button</div>
                        <v-dialog v-model="dialog14" width="500" tile >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="third" dark v-bind="attrs" v-on="on" class="black--text"  large tile>
                                    Open
                                </v-btn>
                            </template>
                            <v-card >
                                <v-card-title class="text-h5 text-black black lighten-2">
                                    Text
                                    <div style="position:absolute;right:20px;">
                                            <v-btn icon dark @click="dialog14 = false" >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-title>
                                <v-card-title class="text-h6 white lighten-2 shadow" style="color:#52D6CD">
                                    Ohjeteksti
                                </v-card-title>

                                <v-card-text style="padding: 40px 24px 40px;align:center;">
                                    Tänne sisältöä
                                </v-card-text>

                                <v-card-actions class="justify-end">
                                        <v-btn large class="naf-btn btnBlock" :style=" 'border:0px solid #fff; color:#000; background:#fff;box-shadow: none;'" tile button @click="dialog14 = false">
                                        PERUUTA
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            
                        </v-dialog>
                    </v-col>
                </v-row>
            </div>            
        </v-app>
    </div>

</template>


<style scoped lang="scss">

.row{
    flex: initial!important;
}


button{
    font-weight: 300;
}

.v-dialog {
  border-radius: 0px!important;
}

.v-sheet.v-card {
    border-radius: 0px!important;
}

.white-btn:hover {
  background-color: #267B8F !important;
  color: #fff!important;

}

.icon-with-text{
  position: absolute;
  top: 0;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>


<script lang="js">

export default {
  
  data () {
    return {
     dialog1: false,
      dialog10: false,
      dialog11: false,
      dialog12: false,
      dialog13: false,
      dialog14: false,
      loading3: false,
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },
  },

}
</script>
