<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>{{ $route.name }}</span>
    </div>

    <h1>{{ $route.name }}</h1>

    <div class="paragraph-template">
      Colors are divided into primary colors, supportive secondary colors and product-specific, distinguishable color palette for applications.
      Always maintain consistent and specified interface colors.  
    </div>

    <div class="paragraph-template">
      Primary Colors
    </div>

    <div class="paragraph-template-sub">
        There are to primary colors supported by their secondary palettes. First primary is gray which is neutral color and is the foundation of our color system.  Second primary is our green. Together they make the basis of our color palette. Colors are always referred by specific names. Only colors defined in this guideline are to be used.
    </div>

    <div class="paragraph-template-sub">
       <span class="bold-title">GSO</span>Primary gray
    </div>

    <div class="color-desc-container">
       <span class="color-desc"><span class="strong">#</span><span>353535</span></span>
       <span class="color-rgb">rgba(53, 53, 53, 1)</span>
      
    </div>

    <div class="color-block gray"></div>

    <div class="paragraph-template-sub">
       <span class="bold-title">PSO</span>Primary green
    </div>

    <div class="color-desc-container">
       <span class="color-desc"><span class="strong">#</span><span>5BC888</span></span>
       <span class="color-rgb">rgba(91, 200, 136, 1)</span>
    </div>

    <div class="color-block green"></div>

    <div class="paragraph-template-sub">
      <span class="bold-title">Support primary colors</span>
      <div class="template-sub-content">
        Primary gray GS0 and primary color green PC0 gain support colors.
      </div>
    </div>
    
    <div class="pcolor-div">
        <div class="row">
          <div class="col-6 col-sm-4 col-md-3 col-xl-2 pcolor-box" v-for="item in ToDoItems" :key="item.id">
            <div class="pca-title">{{item.title}}</div>
            <div class="color-desc"><span>{{item.color}}</span></div>
            <span class="color-rgb">{{item.rgba}}</span>
            <div class="pca-block-color" v-bind:style="{background: item.color}"></div>
          </div> 
        </div>
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <div class="paragraph-template strong-font">
          Accent color
        </div>

        <div class="paragraph-template-sub" style="width:100%">
          Accent color should only be used whenever a certain element must be prominently highlighted.
        </div>

         <div class="row pcolor-div visible-mobile">
          <div class="col-6 pcolor-box">
            <div class="pca-title">AC-P</div>
            <div class="color-desc"><span class="strong">#</span><span>AB49AB</span></div>
            <span class="color-rgb"><span class="strong">R:</span><span>171</span></span>
            <span class="color-rgb"><span class="strong">G:</span><span>73</span></span>
            <span class="color-rgb"><span class="strong">B:</span><span>171</span></span>
             <span class="color-rgb"><span class="strong">A:</span><span>1</span></span>
            <div class="pca-block-color" style="background-color: #AB49AB;"></div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <div class="paragraph-template strong-font">
          Attention color
        </div>

        <div class="paragraph-template-sub" style="width:100%">
          Attention colors are used to indicate different kind of attention states of the user interface for the user.
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <div class="paragraph-template strong-font">
          Admin color
        </div>

        <div class="paragraph-template-sub" style="width:100%">
         Superadmin function color.
        </div>
         <v-row class="pcolor-div visible-mobile">
          <v-col cols="6" class="pcolor-box">
            <div class="pca-title">AC-P</div>
            <div class="color-desc"><span class="strong">#</span><span>E9E916</span></div>
            <span class="color-rgb"><span class="strong">R:</span><span>233</span></span>
            <span class="color-rgb"><span class="strong">G:</span><span>233</span></span>
            <span class="color-rgb"><span class="strong">B:</span><span>22</span></span>
             <span class="color-rgb"><span class="strong">A:</span><span>1</span></span>
            <div class="pca-block-color" style="background-color: #E9E916;"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="align-items-end">
      <v-col cols="12" sm="6" md="4">
        <v-row class="pcolor-div hide-mobile">
          <v-col cols="6" class="pcolor-box">
            <div class="pca-title hidden">AC-P</div>
            <div class="pca-title">AC-P</div>
            <div class="color-desc"><span class="strong">#</span><span>AB49AB</span></div>
            <span class="color-rgb"><span class="strong">R:</span><span>171</span></span>
            <span class="color-rgb"><span class="strong">G:</span><span>173</span></span>
            <span class="color-rgb"><span class="strong">B:</span><span>171</span></span>
             <span class="color-rgb"><span class="strong">A:</span><span>1</span></span>
            <div class="pca-block-color" style="background-color: #AB49AB;"></div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-row class="pcolor-div">
          <v-col cols="6" class="pcolor-box">
            <div class="pca-title">Error indication</div>
            <div class="pca-title">ERR</div>
            <div class="color-desc"><span class="strong">#</span><span>C34B4B</span></div>
            <span class="color-rgb"><span class="strong">R:</span><span>195</span></span>
            <span class="color-rgb"><span class="strong">G:</span><span>75</span></span>
            <span class="color-rgb"><span class="strong">B:</span><span>75</span></span>
             <span class="color-rgb"><span class="strong">A:</span><span>1</span></span>
            <div class="pca-block-color" style="background-color: #C34B4B;"></div>
          </v-col>

          <v-col cols="6" class="pcolor-box">
            <div class="pca-title">Warning</div>
            <div class="pca-title">Warn</div>
            <div class="color-desc"><span class="strong">#</span><span>FFC259</span></div>
            <span class="color-rgb"><span class="strong">R:</span><span>255</span></span>
            <span class="color-rgb"><span class="strong">G:</span><span>194</span></span>
            <span class="color-rgb"><span class="strong">B:</span><span>89</span></span>
             <span class="color-rgb"><span class="strong">A:</span><span>1</span></span>
            <div class="pca-block-color" style="background-color: #FFC259;"></div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="4">
         <v-row class="pcolor-div hide-mobile">
           <v-col cols="6" class="pcolor-box">
            <div class="pca-title hidden">Superadmin</div>
            <div class="pca-title">Superadmin</div>
            <div class="color-desc"><span class="strong">#</span><span>E9E916</span></div>
            <span class="color-rgb"><span class="strong">R:</span><span>233</span></span>
            <span class="color-rgb"><span class="strong">G:</span><span>233</span></span>
            <span class="color-rgb"><span class="strong">B:</span><span>22</span></span>
            <span class="color-rgb"><span class="strong">A:</span><span>1</span></span>
            
            <div class="pca-block-color" style="background-color: #E9E916;"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="paragraph-template">
     Color variations
    </div>

    <div class="paragraph-template-sub">
      Some colors may have light and dark variations, for example to produce gradients for buttons. The variants can be produced by combining with either GS0 (Almost Black) with 30% opacity, producing dark variant (DV) or GS5 (Almost White) with 30% opacity, producing light variant (LV). See example below for Netwheels' accent color calculation.
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="8">
            <div class="two-colors-div">
              <v-row>
                <v-col cols="6">
                  <div class="circle-div first" style="background: rgba(242, 242, 242, 0.3);">
                    <div class="circle-div-text">
                      <div>GS5</div>    
                      <div>#F2F2F2</div>
                      <div><span>R:242</span><span>G:242</span><span>B:242</span></div>
                      <div>Opacity:30%</div>
                    </div>
                  </div>
                 </v-col>

                <v-col cols="6">
                  <div class="circle-div second" style="background: rgba(53, 53, 53, 0.3);">
                    <div class="circle-div-text">
                      <div>GS0 Primary gray</div>
                      <div>#353535</div>
                      <div><span>R:53</span><span>G:53</span><span>B:53</span></div>
                      <div>Opacity:30%</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="color-box-desc">
              <div class="paragraph-template">
                PC+1
              </div>

              <div class="paragraph-template-sub">
                #52D6CD 
              </div>

              <div class="color-desc-container">
                <span class="color-rgb"><span class="strong">R:</span><span>82</span></span>
                <span class="color-rgb"><span class="strong">G:</span><span>214</span></span>
                <span class="color-rgb"><span class="strong">B:</span><span>205</span></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col> 

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="8">
            <div class="two-colors-div pink">
              <v-row>
                <v-col cols="6">
                  <div class="circle-div first" style="background: rgba(242, 242, 242, 0.3);">
                    <div class="circle-div-text">
                      <div>GS5</div>    
                      <div>#F2F2F2</div>
                      <div><span>R:242</span><span>G:242</span><span>B:242</span></div>
                      <div>Opacity:30%</div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="circle-div second" style="background: rgba(53, 53, 53, 0.3);">
                    <div class="circle-div-text">
                      <div>GS0 Primary gray</div>
                      <div>#353535</div>
                      <div><span>R:53</span><span>G:53</span><span>B:53</span></div>
                      <div>Opacity:30%</div>
                    </div>
                  </div>
                </v-col>
             </v-row>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="color-box-desc">
              <div class="paragraph-template">
                AC-P
              </div>

              <div class="paragraph-template-sub">
                #AB49AB
              </div>

              <div class="color-desc-container">
                <span class="color-rgb"><span class="strong">R:</span><span>171</span></span>
                <span class="color-rgb"><span class="strong">G:</span><span>73</span></span>
                <span class="color-rgb"><span class="strong">B:</span><span>171</span></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col> 
    </v-row>


    <div class="paragraph-template">
      Gradients
    </div>

    <div class="paragraph-template-sub">
      Gradients can be produced using theme specific colors. Each application has variations of application-specific gradients. Additionally, gradients can be produced using single color and it’s variations. Gradients are always linear and direction is always from dark to light, from bottom left to top right.
    </div>

    <div class="gradients">
      <div class="row">
        <div class="col-12">
          <div class="gradient-title strong-font">Primary color gradient</div>
          <div class="gradient-paragraph">Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet. Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet a.</div>
        </div>
      </div>
      <div class="steps-div">
          <div class="step5 left-align">
              <div class="step-label strong-font">STEP 1</div>
              <div class="pc-label">PC-1</div>
              <div class="dash-label">#353535</div>
              <div class="rgba-label">rgba(53, 53, 53, 1)</div>
          </div>
          <div class="step5">
              <div class="step-label strong-font">STEP 2</div>
              <div class="pc-label">PC-3</div>
              <div class="dash-label">#425466</div>
              <div class="rgba-label">rgba(66, 84, 102, 1)</div>
          </div>
          <div class="step5">
              <div class="step-label strong-font">STEP 3</div>
              <div class="pc-label">PC-2</div>
              <div class="dash-label">#267B8F</div>
              <div class="rgba-label">rgba(38, 123, 143, 1)</div>
          </div>
          <div class="step5">
              <div class="step-label strong-font">STEP 4</div>
              <div class="pc-label">PC-1</div>
              <div class="dash-label">#00A39B</div>
              <div class="rgba-label">rgba(0, 163, 155, 1)</div>
          </div>
          <div class="step5 right-align">
              <div class="step-label strong-font">STEP 5</div>
              <div class="pc-label">PC-0</div>
              <div class="dash-label">#5bc888</div>
              <div class="rgba-label">rgba(91, 200, 136, 1)</div>
          </div>
      </div>
      <div class="color-block" style="background-image: linear-gradient(to right, #353535, #425466 , #267B8F, #00A39B, #5bc888);"></div>  
    </div>

    <div class="gradients">
      <div class="row">
        <div class="col-12">
          <div class="gradient-title strong-font">Dark color gradient</div>
          <div class="gradient-paragraph">Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet. Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet a.</div>
        </div>
        
      </div>
      <div class="steps-div">
          <div class="step3 left-align">
              <div class="step-label strong-font">STEP 1</div>
              <div class="pc-label">PC-1</div>
              <div class="dash-label">#353535</div>
              <div class="rgba-label">rgba(53, 53, 53, 1)</div>
          </div>
          <div class="step3">
              <div class="step-label strong-font">STEP 2</div>
              <div class="pc-label">PC-3</div>
              <div class="dash-label">#425466</div>
              <div class="rgba-label">rgba(66, 84, 102, 1)</div>
          </div>
          <div class="step3 right-align">
              <div class="step-label strong-font">STEP 3</div>
              <div class="pc-label">PC-2</div>
              <div class="dash-label">#267B8F</div>
              <div class="rgba-label">rgba(38, 123, 143, 1)</div>
          </div>
      </div>
      <div class="color-block" style="background-image: linear-gradient(to right, #353535, #425466 , #267B8F);"></div>
      
    </div>

    <div class="gradients">
      <div class="row">
        <div class="col-12">
          <div class="gradient-title strong-font">Light color gradient</div>
          <div class="gradient-paragraph">Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet. Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet a.</div>
        </div>
        
      </div>
      <div class="steps-div">
          <div class="step3 left-align">
              <div class="step-label strong-font">STEP 1</div>
              <div class="pc-label">PC-3</div>
              <div class="dash-label">#425466</div>
              <div class="rgba-label">rgba(66, 84, 102, 1)</div>
          </div>
          <div class="step3">
              <div class="step-label strong-font">STEP 2</div>
              <div class="pc-label">PC-2</div>
              <div class="dash-label">#267B8F</div>
              <div class="rgba-label">rgba(38, 123, 143, 1)</div>
          </div>
          <div class="step3 right-align">
              <div class="step-label strong-font">STEP 3</div>
              <div class="pc-label">PC-1</div>
              <div class="dash-label">#00A39B</div>
              <div class="rgba-label">rgba(0, 163, 155, 1)</div>
          </div>
      </div>
      <div class="color-block" style="background-image: linear-gradient(to right, #425466, #267B8F, #00A39B);"></div>
      
    </div>

     
  </div>
</template>

<style scoped lang="less">

.hidden{
  opacity: 0;
}

.hide-mobile{
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.visible-mobile{
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}

.bold-title{
  font-weight: bold;
  padding-right: 10px;
}

.color-desc-container{
  margin-top: 10px;
}

.color-desc{
  padding-right: 20px;
}

.color-rgb{
    padding-right: 6px;

}

.strong{
  font-weight: bold;
  padding-right: 3px;
}

.color-block{
  margin-top: 20px;
  height: 80px;
  width: 100%;
}

.color-block.gray{
  background-color: #353535;
}

.color-block.green{
  background-color: #5BC888;
}

.template-sub-content{
  margin-top: 10px;
}

.pcolor-div{
  margin-top: 20px;
}

#test{
  display: none;
}

.pcolor-div{
  .pcolor-box{
    margin-bottom: 20px;
    .pca-title{
      font-size: 18px;
      margin-bottom: 10px;
    }
    .color-desc{
      margin-bottom: 8px;
    }
    .color-rgb{
      margin-bottom: 8px;
    }
    .pca-block-color{
      height: 15px;
      width: 100%;
      
    }
  }

}

.two-colors-div{
  margin-top: 50px;
  background-color: #52D6CD;
  .circle-div{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 50%;
    position: relative;
    height: 130px;
    width:130px;
    color: #000;

    .circle-div-text{
      padding: 10px;
      display: inline-block;
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
      top: 0%;

      @media (max-width: 660px) {
        top: 0%;
      }
    
    }
  }
}

.two-colors-div.pink{
  background-color:#AB49AB ;
}

.color-box-desc{
  padding-top: 10px;
  @media (max-width: 1190px) {
    .paragraph-template{
      margin-top: 15px;
    }
    .paragraph-template-sub{
      margin-top: 10px;
    }
  }
  
}

.gradients{
  margin-top: 30px;
} 

.steps-div{
  display: inline-flex;
  width: 100%;
  margin-top: 20px;
  .step5{
    width: 20%;
    
  }
  .step3{
    width: 33.33%;
   
  }
  .step2{
    width:50%;
   
  }
  
  .step-label{
    font-size: 13px;
  }
  .pc-label{
    font-size: 18px;
    margin-bottom: 8px;
  }
  .dash-label{
    font-size: 15px;
  }
  .rgba-labe{
    font-size: 15px;
    margin-bottom: 5px;
  }
}

.strong-font{
  font-weight: 700 ;
}

.gradient-paragraph{
  width: 30%;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

</style>

<script>

  import uniqueId from 'lodash.uniqueid'

  export default {
  name: 'app',
  
  data() {
    return {
      ToDoItems: [
        { id: uniqueId('todo-'), title: 'PC-3', color: '#425466', rgba: 'rgba(66, 84, 102, 1)'},
        { id: uniqueId('todo-'), title: 'PC-2', color: '#267B8F', rgba: 'rgba(38, 123, 143, 1)'},
        { id: uniqueId('todo-'), title: 'PC-1', color: '#00A39B', rgba: 'rgba(0, 163, 155, 1)'},
        { id: uniqueId('todo-'), title: 'PC+1', color: '#52D6CD', rgba: 'rgba(82, 214, 205, 1)'},
        { id: uniqueId('todo-'), title: 'PC+2', color: '#91DEF4', rgba: 'rgba(145, 222, 244, 1)'},
        { id: uniqueId('todo-'), title: 'PC+3', color: '#D1E4FA', rgba: 'rgba(209, 228, 250, 1)'},
        { id: uniqueId('todo-'), title: 'GS1', color: '#595959', rgba: 'rgba(89, 89, 89, 1)'},
        { id: uniqueId('todo-'), title: 'GS2', color: '#7F7F7F', rgba: 'rgba(127, 127, 127, 1)'},
        { id: uniqueId('todo-'), title: 'GS3', color: '#A8A8A8', rgba: 'rgba(168, 168, 168, 1)'},
        { id: uniqueId('todo-'), title: 'GS4', color: '#D3D3D3', rgba: 'rgba(211, 211, 211, 1)'},
        { id: uniqueId('todo-'), title: 'GS5', color: '#F2F2F2', rgba: 'rgba(242, 242, 242, 1)'},
        { id: uniqueId('todo-'), title: 'GS6', color: '#FFFFFF', rgba: 'rgba(255, 255, 255, 1)'},

      ]
    };
  }
};


</script>






