import { render, staticRenderFns } from "./Color.vue?vue&type=template&id=4ad6b87a&scoped=true&"
import script from "./Color.vue?vue&type=script&lang=js&"
export * from "./Color.vue?vue&type=script&lang=js&"
import style0 from "./Color.vue?vue&type=style&index=0&id=4ad6b87a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ad6b87a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
