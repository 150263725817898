var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-container"},[_c('div',{staticClass:"breadcrumbs-div"},[_c('router-link',{staticClass:"breadcrumbs-home",attrs:{"to":"/"}},[_c('span',[_vm._v("NUIG Home")])]),_c('i',{staticClass:"fas fa-chevron-right"}),_c('span',[_vm._v(_vm._s(_vm.$route.name))])],1),_c('h1',[_vm._v(_vm._s(_vm.$route.name))]),_c('div',{staticClass:"paragraph-template"},[_vm._v(" Date pickers allow users to select a time or date, or schedule tasks. ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Date pickers come in two orientation variations, portrait (default) and landscape. By default they are emitting input event when the day (for date picker) or month (for month picker), but with reactive prop they can update the model even after clicking year/month. ")]),_vm._m(0),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ")]),_vm._m(1),_c('div',{staticClass:"paragraph-template"},[_vm._v(" Date Pickers ")]),_c('v-app',{attrs:{"id":"inspire"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn-toggle',{model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-calendar ")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Picker in menu","prepend-icon":"","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"secondary"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn-toggle',[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-calendar ")]),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Picker in dialog","prepend-icon":"","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","color":"secondary"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn-toggle',[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-calendar ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Picker without buttons","prepend-icon":"","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template"},[_c('h3',[_vm._v(" Datepicker variants")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Click here to read: "),_c('a',{staticClass:"arrow-new-tab",attrs:{"href":"#","target":"_blank"}},[_vm._v(" Datepicker documentation "),_c('i',{staticClass:"far fa-arrow-alt-circle-right"})])])}]

export { render, staticRenderFns }