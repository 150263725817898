var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-container"},[_c('div',{staticClass:"breadcrumbs-div"},[_c('router-link',{staticClass:"breadcrumbs-home",attrs:{"to":"/"}},[_c('span',[_vm._v("NUIG Home")])]),_c('i',{staticClass:"fas fa-chevron-right"}),_c('span',[_vm._v(_vm._s(_vm.$route.name))])],1),_c('h1',[_vm._v(_vm._s(_vm.$route.name))]),_c('div',{staticClass:"paragraph-template"},[_vm._v(" The Time-picker is stand-alone component that can be utilized in many existing Vuetify components. It offers the user a visual representation for selecting the time. ")]),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Time pickers have the light theme enabled by default. ")]),_vm._m(0),_c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ")]),_vm._m(1),_c('v-app',{attrs:{"id":"inspire"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"custom-group"},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-clock-time-four-outline")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Picker in menu","prepend-icon":"","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"color":"secondary","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e(),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.time)}}},[_vm._v(" OK ")])],1)],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"custom-group"},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-clock-time-four-outline")]),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.time,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Picker in dialog","prepend-icon":"","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"color":"secondary","full-width":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.time)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"custom-group"},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-clock-time-four-outline")]),_c('v-menu',{ref:"menu",attrs:{"color":"secondary","close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Picker without buttons","prepend-icon":"","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[(_vm.menu3)?_c('v-time-picker',{attrs:{"color":"secondary","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1)]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template"},[_c('h3',[_vm._v(" Timepicker upload variants")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paragraph-template-sub"},[_vm._v(" Click here to read: "),_c('a',{staticClass:"arrow-new-tab",attrs:{"href":"#","target":"_blank"}},[_vm._v(" Timepicker documentation "),_c('i',{staticClass:"far fa-arrow-alt-circle-right"})])])}]

export { render, staticRenderFns }