<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
        <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>{{ $route.name }}</span>
    </div>

    <h1>Theme: {{ $route.name }}</h1>
    <div class="paragraph-template">
       Each Netwheels application has it’s own distinctive theme. While sharing fundamental components and elements trough all the applications, an application color palette is used to differentiate each individually.
    </div>

    <div class="paragraph-template">
       Application Colors
    </div>

    <div class="paragraph-template-sub">
      Applications have their own color palette for creating distinguishable look and feel for each individual application. Each application palette is supported with all the primary grays from the primary color palette.
    </div>

    <div class="paragraph-template">
       GT-X
    </div>

    <div class="paragraph-template-sub small-paragraph">
      The GT-X software consists of two main modules.
      The vehicle modeling and warehouse, which handles distribution channel sales and inventory management. Modeling and Warehouse work seamlessly together, in the same GT-X interface. All functions are also available on the mobile.
    </div>
   
    <v-row>
      <v-col cols="12" sm="6">
        <div class="paragraph-template-sub">
            <span class="bold-title">GT-X</span>AC0
        </div>

        <div class="color-desc-container">
          <span class="color-desc"><span>#</span><span>5AB2ED</span></span>
          <span class="color-rgb"><span>rgba</span><span>(90, 178, 237, 1)</span></span>
        </div>

        <div class="color-block" style="background-color: #5AB2ED;"></div>
      </v-col>

      <v-col cols="12" sm="6">
        <div class="paragraph-template-sub">
          <span class="bold-title">PSO</span>Primary gray
        </div>

        <div class="color-desc-container">
          <span class="color-desc"><span>#</span><span>353535</span></span>
          <span class="color-rgb"><span>rgba</span><span>(53, 53, 53, 1)</span></span>
        </div>

        <div class="color-block" style="background-color: #353535;"></div>
      </v-col>
    </v-row>

     <div class="pcolor-div">
        <v-row>
          <v-col cols="12" sm="4" md="3" xl="2" class="pcolor-box" v-for="item in ToDoItems" :key="item.id">
            <div class="pca-title">{{item.title}}</div>
            <div class="color-desc"><span>{{item.color}}</span></div>
            <span class="color-rgb">{{item.rgba}}</span>
            <div class="pca-block-color" v-bind:style="{background: item.color}"></div>
          </v-col> 
        </v-row>
    </div>

    <div class="paragraph-template">
      Theme Gradients
    </div>

    <div class="paragraph-template-sub">
      Gradients can be produced using theme specific colors. Each application has variations of application-specific gradients. Additionally, gradients can be produced using single color and it’s variations. Gradients are always linear and direction is always from dark to light, from bottom left to top right.
    </div>   

    <div class="gradients">
      <div class="row">
        <div class="col-12">
          <div class="gradient-title strong-font">GT-X dark gradient</div>
          <div class="gradient-paragraph">Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet. Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet a.</div>
        </div>
        
      </div>
      <div class="steps-div">
          <div class="step3 left-align">
              <div class="step-label strong-font">STEP 1</div>
              <div class="pc-label">GS0</div>
              <div class="dash-label">#353535</div>
              <div class="rgba-label">rgba(53, 53, 53, 1)</div>
          </div>
          <div class="step3">
              <div class="step-label strong-font">STEP 2</div>
              <div class="pc-label">GT-X AC-3</div>
              <div class="dash-label">#4D4F5E</div>
              <div class="rgba-label">rgba(77, 79, 94, 1)</div>
          </div>
          <div class="step3 right-align">
              <div class="step-label strong-font">STEP 3</div>
              <div class="pc-label">GT-X AC-2</div>
              <div class="dash-label">#5E6D8C</div>
              <div class="rgba-label">rgba(94, 109, 140, 1)</div>
          </div>
      </div>
      <div class="color-block" style="background-image: linear-gradient(to right, #353535, #4D4F5E , #5E6D8C);"></div>
      
    </div>

    <div class="gradients">
      <div class="row">
        <div class="col-12">
          <div class="gradient-title strong-font">GT-X light gradient</div>
          <div class="gradient-paragraph">Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet. Quisque sed quam ligula. Sed at elit gravida, pellentesque ante ac, sodales nulla. Proin pulvinar erat orci, id molestie mi laoreet a.</div>
        </div>
        
      </div>
      <div class="steps-div">
          <div class="step3 left-align">
              <div class="step-label strong-font">STEP 1</div>
              <div class="pc-label">GT-X AC-3</div>
              <div class="dash-label">#4D4F5E</div>
              <div class="rgba-label">rgba(77, 79, 94, 1)</div>
          </div>
          <div class="step3">
              <div class="step-label strong-font">STEP 2</div>
              <div class="pc-label">GT-X AC-2</div>
              <div class="dash-label">#5E6D8C</div>
              <div class="rgba-label">rgba(94, 109, 140, 1)</div>
          </div>
          <div class="step3 right-align">
              <div class="step-label strong-font">STEP 3</div>
              <div class="pc-label">GT-X AC-1</div>
              <div class="dash-label">#648EBC</div>
              <div class="rgba-label">rgba(100, 142, 188, 1)</div>
          </div>
      </div>
      <div class="color-block" style="background-image: linear-gradient(to right, #4D4F5E, #5E6D8C, #648EBC);"></div>
      
    </div>

  </div>
</template>


<style scoped lang="scss">

.hidden{
  opacity: 0;
}

.hide-mobile{
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.visible-mobile{
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}

.bold-title{
  font-weight: bold;
  padding-right: 10px;
}

.color-desc-container{
  margin-top: 10px;
}

.color-desc{
  padding-right: 20px;
}

.color-rgb{
  padding-right: 15px;
}

.strong{
  font-weight: bold;
  padding-right: 3px;
}

.color-block{
  margin-top: 20px;
  height: 80px;
  width: 100%;
}

.template-sub-content{
  margin-top: 10px;
}

.pcolor-div{
  margin-top: 40px;
}

#test{
  display: none;
}

.pcolor-div{
  .pcolor-box{
    margin-bottom: 20px;
    .pca-title{
      font-size: 18px;
      margin-bottom: 10px;
    }
    .color-desc{
      margin-bottom: 8px;
    }
    .color-rgb{
      margin-bottom: 5px;
      display: block;

    }
    .pca-block-color{
      height: 15px;
      width: 100%;
      
    }
  }

}

.two-colors-div{
  margin-top: 50px;
  background-color: #52D6CD;
  .circle-div{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 50%;
    position: relative;
    height: 130px;
    width:130px;
    color: #000;

    .circle-div-text{
      padding: 10px;
      display: inline-block;
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
      top: 0%;

      @media (max-width: 660px) {
        top: 0%;
      }
    
    }
  }
}

.two-colors-div.pink{
  background-color:#AB49AB ;
}

.small-paragraph{
  font-size: 15px;
  width: 40%;
  @media (max-width: 660px) {
    width: 100%;
  }
}

.color-box-desc{
  padding-top: 10px;
  @media (max-width: 1190px) {
    .paragraph-template{
      margin-top: 15px;
    }
    .paragraph-template-sub{
      margin-top: 10px;
    }
  }
  
}


.gradients{
  margin-top: 30px;
} 

.steps-div{
  display: inline-flex;
  width: 100%;
  margin-top: 20px;
  .step5{
    width: 20%;
    
  }
  .step3{
    width: 33.33%;
   
  }
  .step2{
    width:50%;
   
  }
  
  .step-label{
    font-size: 13px;
  }
  .pc-label{
    font-size: 18px;
    margin-bottom: 8px;
  }
  .dash-label{
    font-size: 15px;
  }
  .rgba-labe{
    font-size: 15px;
    margin-bottom: 5px;
  }
}

.strong-font{
  font-weight: 700 ;
}

.gradient-paragraph{
  width: 30%;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

</style>

<script>
  import uniqueId from 'lodash.uniqueid'
  export default {
  name: 'app',
  
  data() {
    return {
      ToDoItems: [
        { id: uniqueId('todo-'), title: 'GT-X AC-3', color: '#4D4F5E', rgba: 'rgba(77, 79, 94, 1)'},
        { id: uniqueId('todo-'), title: 'GT-X AC-2', color: '#5E6D8C', rgba: 'rgba(94, 109, 140, 1)'},
        { id: uniqueId('todo-'), title: 'GT-X AC-1', color: '#648EBC', rgba: 'rgba(100, 142, 188, 1)'},
        { id: uniqueId('todo-'), title: 'GT-X AC+1', color: '#96BFF0', rgba: 'rgba(150, 191, 240, 1)'},
        { id: uniqueId('todo-'), title: 'GT-X AC+2', color: '#BECEF1', rgba: 'rgba(190, 206, 241, 1)'},
        { id: uniqueId('todo-'), title: 'GT-X AC+3', color: '#DDDFF1', rgba: 'rgba(221, 223, 241, 1)'},
        { id: uniqueId('todo-'), title: 'GS1', color: '#595959', rgba: 'rgba(89, 89, 89, 1)'},
        { id: uniqueId('todo-'), title: 'GS2', color: '#7F7F7F', rgba: 'rgba(127, 127, 127, 1)'},
        { id: uniqueId('todo-'), title: 'GS3', color: '#A8A8A8', rgba: 'rgba(168, 168, 168, 1)'},
        { id: uniqueId('todo-'), title: 'GS4', color: '#D3D3D3', rgba: 'rgba(211, 211, 211, 1)'},
        { id: uniqueId('todo-'), title: 'GS5', color: '#F2F2F2', rgba: 'rgba(242, 242, 242, 1)'},
        { id: uniqueId('todo-'), title: 'GS6', color: '#FFFFFF', rgba: 'rgba(255, 255, 255, 1)'},
        { id: uniqueId('todo-'), title: 'GT-X accent', color: '#B55608', rgba: 'rgba(181, 86, 8, 1)'},
      ],

      ToDoItems2: [
        { id: uniqueId('todo-'), title: 'Autohuuto AC-3', color: '#67495F', rgba: 'rgba(103, 73, 95, 1)'},
        { id: uniqueId('todo-'), title: 'Autohuuto AC-2', color: '#AC5765', rgba: 'rgba(172, 87, 101, 1)'},
        { id: uniqueId('todo-'), title: 'Autohuuto AC-1', color: '#DB7647', rgba: 'rrgba(219, 118, 71, 1)'},
        { id: uniqueId('todo-'), title: 'Autohuuto AC+1', color: '#FFA775', rgba: 'rgba(255, 167, 117, 1)'},
        { id: uniqueId('todo-'), title: 'Autohuuto AC+2', color: '#FFB8C5', rgba: 'rgba(255, 184, 197, 1)'},
        { id: uniqueId('todo-'), title: 'Autohuuto AC+3', color: '#FCD8F2', rgba: 'rgba(252, 216, 242, 1)'},
        { id: uniqueId('todo-'), title: 'GS1', color: '#595959', rgba: 'rgba(89, 89, 89, 1)'},
        { id: uniqueId('todo-'), title: 'GS2', color: '#7F7F7F', rgba: 'rgba(127, 127, 127, 1)'},
        { id: uniqueId('todo-'), title: 'GS3', color: '#A8A8A8', rgba: 'rgba(168, 168, 168, 1)'},
        { id: uniqueId('todo-'), title: 'GS4', color: '#D3D3D3', rgba: 'rgba(211, 211, 211, 1)'},
        { id: uniqueId('todo-'), title: 'GS5', color: '#F2F2F2', rgba: 'rgba(242, 242, 242, 1)'},
        { id: uniqueId('todo-'), title: 'GS6', color: '#FFFFFF', rgba: 'rgba(255, 255, 255, 1)'},
        { id: uniqueId('todo-'), title: 'Autohuuto accent', color: '#3F54B0', rgba: 'rgba(63, 84, 176, 1)'},
      ]
    };
  }
};

</script>





