<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>Components</span>
    </div>
    <h1>Components</h1>
    <div class="paragraph-template">
    At the core fo Netwheels user interface are the components. How they are are designed and implemented, and how they react and respond when interacted with various devices and in different environments.
    </div>
   
    <div class="paragraph-template">
      Component fundamentals
    </div>
    <div class="paragraph-template-sub">
      With every user interface component designed and developed the overall dimensions are always divisible by eight (8) to maintain proper size contrast relation with layout. Naturally the width of each specific component varies context-wise.
    </div>
    <div class="paragraph-template-sub">
      Color and other visual variations for the components are derived fron the selected application theme. No other specific differences excist for the components and they are structuraly same trough use of all Netwheels user interfaces.
    </div>
    <div class="paragraph-template-sub">
      Each component is set to do a specific task and most of them also have individual specifications for various different states
    </div>

    <div class="paragraph-template">
      List of Components
    </div>

    <div class="component-list-div">
      <v-row>
 
        <v-col cols="12" sm="6" md="4" v-for="item in componentsItem"  :key="item.id">
          <router-link :to="{path:item.link}" class="sub-components">
            <div class="component-box">
              <div class="component-title">{{item.title}}</div>
              <div class="component-content">{{item.content}}</div>
              <div class="component-button"><span>Read more<i class="far fa-arrow-alt-circle-right"></i></span></div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<style scoped lang="scss">

.component-list-div{
  margin-top: 20px;
  a{
    text-decoration: none;
    .component-box{
      background-color:#F2F2F2;
      padding: 20px;
      margin-bottom: 20px;
      position: relative;
      min-height: 250px;
      .component-title{
        font-size: 20px;
        margin-bottom: 10px;
      }

      .component-content{
        margin-bottom: 40px;
      }

      .component-button{
        position: absolute;
        bottom: 20px;
        i{
          padding-left: 10px;
        }
      }
    }

  }
  a:hover {
     text-decoration: none;
  }
}
</style>

<script>
  import uniqueId from 'lodash.uniqueid'
  export default {
  name: 'app',
  
  data() {
    return {
      componentsItem: [
        { 
          id: uniqueId('todo-'),
          title: 'Button', 
          content: 'Buttons are one of the primary components of applications interaction. They are used to call  various actions by users and allow them to interact with pages in a variety of ways.', 
          link: '/components/button'
        },
        { id: uniqueId('todo-'),
          title: 'Checkboxes', 
          content: 'The checkbox component provides users the ability to choose between two distinct values.', 
          link: '/components/checkboxes'
        },
        { id: uniqueId('todo-'),
          title: 'Date picker', 
          content: 'Date pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/date-picker'
        },
        { id: uniqueId('todo-'),
          title: 'Dialog', 
          content: 'The dialog component inform users about a specific task and may contain critical information, require decisions, or involve multiple tasks. Use dialogs sparingly because they are interruptive.', 
          link: '/components/dialog'
        },
        { id: uniqueId('todo-'),
          title: 'Dropdown', 
          content: 'Date pickers allow users to select a time or date, or schedule tasks.', 
          link: '/components/dropdown'
        },
        { id: uniqueId('todo-'),
          title: 'File upload', 
          content: 'The file upload component is a specialized input that provides a clean interface for selecting files, showing detailed selection information and upload progress. It is meant to be a direct replacement for a standard file input.', 
          link: '/components/file-upload'
        },
        { id: uniqueId('todo-'),
          title: 'Naf-fab Menu', 
          content: 'NAF-FAB Menu component shows a menu at the position of the element used to activate it.', 
          link: '/components/naf-fab-menu'
        },
        { id: uniqueId('todo-'),
          title: 'Radio Button', 
          content: 'The radio component is a simple radio button. When combined with the v-radio-group component you can provide groupable functionality to allow users to select from a predefined set of options.', 
          link: '/components/radio-button'
        },
        { id: uniqueId('todo-'),
          title: 'Snackbar', 
          content: 'The snackbar component is used to display a quick message to a user. Snackbars support positioning, removal delay, and callbacks.', 
          link: '/components/snackbar'
        },
        { id: uniqueId('todo-'),
          title: 'Tags', 
          content: 'The tags component is used to convey small pieces of information. Using the close property, the chip becomes interactive, allowing user interaction. This component is used by the v-chip-group for advanced selection options.', 
          link: '/components/tags'
        },
        { id: uniqueId('todo-'),
          title: 'Text field', 
          content: 'Text fields components are used for collecting user provided information.', 
          link: '/components/text-field'
        },
        { id: uniqueId('todo-'),
          title: 'Textarea', 
          content: 'Textarea components are used for collecting large amounts of textual data.', 
          link: '/components/text-area'
        },
        { id: uniqueId('todo-'),
          title: 'Time picker', 
          content: 'The Time-picker is stand-alone component that can be utilized in many existing Vuetify components. It offers the user a visual representation for selecting the time.', 
          link: '/components/time-picker'
        },
        { id: uniqueId('todo-'),
          title: 'Toggle Button', 
          content: 'The toggle button component is a simple wrapper for v-item-group built specifically to work with v-btn.', 
          link: '/components/toggle-button'
        }
        
      ]
    };
  },
  
};

</script>


