<template>
  <div class="right-container">
    <div class="breadcrumbs-div">
      <router-link to="/" class="breadcrumbs-home">
          <span>NUIG Home</span>
      </router-link>
      <i class="fas fa-chevron-right"></i>
      <span>{{ $route.name }}</span>
    </div>
    <h1>{{ $route.name }}</h1>
    <div class="paragraph-template">
     The Time-picker is stand-alone component that can be utilized in many existing Vuetify components. It offers the user a visual representation for selecting the time.
    </div>
    <div class="paragraph-template-sub">
     Time pickers have the light theme enabled by default.
    </div>

     <div class="paragraph-template">
      <h3> Timepicker upload variants</h3>
    </div>
    <div class="paragraph-template-sub">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
    </div>
    <div class="paragraph-template-sub">
      Click here to read: <a class="arrow-new-tab" href="#" target="_blank"> Timepicker documentation <i class="far fa-arrow-alt-circle-right"></i></a>
    </div>
   
    
       <v-app id="inspire">
        <v-row>

          <v-col cols="12" sm="6" md="4">
            <div class="custom-group">
              <v-icon dark left >  mdi-clock-time-four-outline</v-icon>
              <v-menu ref="menu" v-model="menu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" offset-y  max-width="290px" min-width="290px" >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field color="secondary" v-model="time" label="Picker in menu" prepend-icon="" readonly v-bind="attrs" v-on="on"  ></v-text-field>
                </template>
                <v-time-picker color="secondary" v-if="menu2" v-model="time" full-width  @click:minute="$refs.menu.save(time)" ></v-time-picker>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="menu2 = false" >
                  Cancel
                </v-btn>
                <v-btn text color="secondary" @click="$refs.dialog.save(time)"  >
                  OK
                </v-btn>
              </v-menu>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="4" >
             <div class="custom-group">
              <v-icon dark left >  mdi-clock-time-four-outline</v-icon>
              <v-dialog ref="dialog" v-model="modal2" :return-value.sync="time" persistent width="290px" >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field color="secondary" v-model="time" label="Picker in dialog" prepend-icon="" readonly  v-bind="attrs" v-on="on" ></v-text-field>
                </template>
                <v-time-picker color="secondary" v-if="modal2" v-model="time" full-width >
                  <v-spacer></v-spacer>
                  <v-btn text color="secondary" @click="modal2 = false" >
                    Cancel
                  </v-btn>
                  <v-btn text color="secondary" @click="$refs.dialog.save(time)"  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
             </div >
          </v-col>

          <v-col  cols="12"  sm="6" md="4" >
            <div class="custom-group">
              <v-icon dark left >  mdi-clock-time-four-outline</v-icon>
              <v-menu ref="menu" color="secondary" v-model="menu3" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" offset-y  max-width="290px" min-width="290px" >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field color="secondary" v-model="time" label="Picker without buttons" prepend-icon="" readonly v-bind="attrs" v-on="on" ></v-text-field>
                </template>
                <v-time-picker color="secondary" v-if="menu3" v-model="time" full-width  @click:minute="$refs.menu.save(time)" ></v-time-picker>
              </v-menu>
            </div >
          </v-col>

          <v-spacer></v-spacer>
        </v-row>
      </v-app>
    </div>

</template>

<style lang="scss" scoped>

.mdi-checkbox-marked-circle::before {
    content: "\F0133";
    color: #267B8F;
}
.mdi-paperclip::before {
    content: "\F03E2";
    color: #00a39b!important;
}
.mdi-paperclip::before {
    content: "\F03E2";
     color: #00a39b!important;
}
.mdi:before{
  color: #00a39b!important;
}


.v-icon.v-icon::after {
  color: #00a39b!important;
}

.custom-group{
  display: flex;
}
</style>

<script>

export default {

  data () {
    return {
      time: null,
      menu2: false,
      menu3: false,
      modal2: false,
    

    }
  }

}
</script>















